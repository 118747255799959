/* App overall layout and background */
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  font-family: 'Arial', sans-serif;
}

/* Header section for better visual structure */
.App-header {
  background-color: #282c34;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  color: white; 
}

/* Input field styling */
input[type="text"] {
  padding: 0.8rem;
  margin-right: 1rem;
  border: 2px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 110px);
  max-width: 300px;
  outline: none;
}

input[type="text"]:focus {
  border-color: #0056b3;
}

/* Button styling */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.8rem 1.6rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Image display styling */
img {
  margin-top: 2rem;
  width: 50%;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* App.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%; /* Ensures the container takes full width */
}